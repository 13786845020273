/* eslint-disable require-jsdoc */
/* eslint-disable valid-jsdoc */
import axios from 'axios';
import constantsFactory from '../../src/utils/constants';
import {
  STATUS_LOADING,
  LOAD_SEARCH_PREVIEW_REQUEST,
  LOAD_SEARCH_PREVIEW_SUCCESS,
  LOAD_SEARCH_PREVIEW_FAILURE,
  LOAD_GALLERY_SEARCH_REQUEST,
  LOAD_GALLERY_SEARCH_SUCCESS,
  LOAD_GALLERY_SEARCH_FAILURE,
  CLEAR_GALLERY_SEARCH_RESULTS,
  SEED_GALLERY_SEARCH_DATA_REQUEST,
  SEED_GALLERY_SEARCH_DATA_SUCCESS,
  SEED_GALLERY_SEARCH_DATA_FAILURE,
} from '../actionTypes';
import paramsHelper from '../../src/utils/paramsHelper';

const {API} = constantsFactory();
const defaultHost = 'https://www.artfinder.com';
const HOST: string = process.env['HOST'] || defaultHost;

const galleryActions = ({galleryType, gallerySlug}: GalleryActionProps) => {
  const gallery = {
    plp: {
      searchPreview: {
        loadRequest: LOAD_SEARCH_PREVIEW_REQUEST,
        loadSuccess: LOAD_SEARCH_PREVIEW_SUCCESS,
        loadFailure: LOAD_SEARCH_PREVIEW_FAILURE,
        url: `${HOST}${API.PRODUCT_COUNT_API}`,
      },
      search: {
        loadRequest: LOAD_GALLERY_SEARCH_REQUEST,
        loadSuccess: LOAD_GALLERY_SEARCH_SUCCESS,
        loadFailure: LOAD_GALLERY_SEARCH_FAILURE,
        url: `${HOST}${API.PRODUCT_SEARCH_API}`,
      },
      seedSearch: {
        loadRequest: SEED_GALLERY_SEARCH_DATA_REQUEST,
        loadSuccess: SEED_GALLERY_SEARCH_DATA_SUCCESS,
        loadFailure: SEED_GALLERY_SEARCH_DATA_FAILURE,
        url: undefined,
      },
      clearSearch: {
        type: CLEAR_GALLERY_SEARCH_RESULTS,
      },
    },
    collection: {
      searchPreview: {
        loadRequest: undefined,
        loadSuccess: undefined,
        loadFailure: undefined,
        url: undefined,
      },
      search: {
        loadRequest: LOAD_GALLERY_SEARCH_REQUEST,
        loadSuccess: LOAD_GALLERY_SEARCH_SUCCESS,
        loadFailure: LOAD_GALLERY_SEARCH_FAILURE,
        url: `${HOST}${API.COLLECTION_API}${gallerySlug}/`,
      },
      seedSearch: {
        loadRequest: SEED_GALLERY_SEARCH_DATA_REQUEST,
        loadSuccess: SEED_GALLERY_SEARCH_DATA_SUCCESS,
        loadFailure: SEED_GALLERY_SEARCH_DATA_FAILURE,
        url: undefined,
      },
      clearSearch: {
        type: CLEAR_GALLERY_SEARCH_RESULTS,
      },
    },
    artist: {
      searchPreview: {
        loadRequest: undefined,
        loadSuccess: undefined,
        loadFailure: undefined,
        url: undefined,
      },
      search: {
        loadRequest: LOAD_GALLERY_SEARCH_REQUEST,
        loadSuccess: LOAD_GALLERY_SEARCH_SUCCESS,
        loadFailure: LOAD_GALLERY_SEARCH_FAILURE,
        url: `${HOST}/api/${gallerySlug}/products/`,
      },
      seedSearch: {
        loadRequest: undefined,
        loadSuccess: undefined,
        loadFailure: undefined,
        url: undefined,
      },
      clearSearch: {
        type: CLEAR_GALLERY_SEARCH_RESULTS,
      },
    },
  };

  /**
   * Gallery item count - loacated on filter search button
   */
  function searchPreview(
    params,
    onSuccessCallbacks = [],
    onErrorCallbacks = [],
    cleanupCallbacks = [],
    signal,
  ) {
    if (!gallery[galleryType].searchPreview.url) return;

    const query = paramsHelper(params).objectToQueryString();

    return {
      // Types of actions to emit before and after
      types: [
        gallery[galleryType].searchPreview.loadRequest,
        gallery[galleryType].searchPreview.loadSuccess,
        gallery[galleryType].searchPreview.loadFailure,
      ],
      // Check if we already have a cached result (optional):
      shouldCallAPI: (state) => state.gallery.status.searchPreview !== STATUS_LOADING,
      // Perform the fetching:
      callAPI: () =>
        axios.get(`${gallery[galleryType].searchPreview.url}${query}`, {
          headers: {Accept: 'application/json', 'x-api-key': process.env.API_AUTH_X_API_KEY},
          signal: signal,
        }),
      // Arguments to inject in begin/end actions
      payload: {},
      // Functions to run on success
      onSuccess: onSuccessCallbacks,
      // Functions to run to on cleanup
      onCleanup: cleanupCallbacks,
      // Functions to run on Error
      onError: onErrorCallbacks,
    };
  }

  /**
   * Gallery search
   */
  function search(
    params: SearchParams,
    onSuccessCallbacks: CallbackType = [],
    onErrorCallbacks: CallbackType = [],
    cleanupCallbacks: CallbackType = [],
    signal: AbortSignal | undefined = undefined,
  ) {
    const query = paramsHelper(params).objectToQueryString();

    return {
      // Types of actions to emit before and after
      types: [
        gallery[galleryType].search.loadRequest,
        gallery[galleryType].search.loadSuccess,
        gallery[galleryType].search.loadFailure,
      ],
      shouldCallAPI: (state) => state.gallery.status.search !== STATUS_LOADING,
      // Perform the fetching:
      callAPI: () =>
        axios.get(`${gallery[galleryType].search.url}${query}`, {
          headers: {Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest'},
          signal: signal,
        }),
      // Retrieve from cache
      callCache: (state) => {
        if (!state.gallery.data.results[params.page ?? 1]) return false;
        return {
          data: {
            results: state.gallery.data.results[params.page ?? 1],
            count: state.gallery.data.totalCount,
            num_pages: state.gallery.data.totalPageCount,
          },
        };
      },
      // Arguments to inject in begin/end actions
      payload: {page: params.page},
      // Functions to run on success
      onSuccess: onSuccessCallbacks,
      // Functions to run to on cleanup
      onCleanup: cleanupCallbacks,
      // Functions to run on Error
      onError: onErrorCallbacks,
    };
  }

  /**
   * Gallery seed search data
   */
  function seedSearch(
    data,
    page = 1,
    onSuccessCallbacks = [],
    onErrorCallbacks = [],
    cleanupCallbacks = [],
  ) {
    return {
      // Types of actions to emit before and after
      types: [
        gallery[galleryType].seedSearch.loadRequest,
        gallery[galleryType].seedSearch.loadSuccess,
        gallery[galleryType].seedSearch.loadFailure,
      ],
      shouldCallAPI: (state) => state.gallery.status.seedData !== STATUS_LOADING,
      // Perform the fetching:
      callAPI: () =>
        new Promise((resolve, reject) => {
          if (data && data.hasOwnProperty('results')) {
            resolve({data: data});
          } else {
            reject(new Error("PLP seed data doesn't have results"));
          }
        }),
      // Retrieve from cache
      callCache: (state) => {
        if (!state.gallery.data.results[page ?? 1]) return false;
        return {
          data: {
            results: state.gallery.data.results[page ?? 1],
            count: state.gallery.data.totalCount,
            num_pages: state.gallery.data.totalPageCount,
          },
        };
      },
      // Arguments to inject in begin/end actions
      payload: {page: page},
      // Functions to run on success
      onSuccess: onSuccessCallbacks,
      // Functions to run to on cleanup
      onCleanup: cleanupCallbacks,
      // Functions to run on Error
      onError: onErrorCallbacks,
    };
  }

  const clearSearchResults = () => ({type: gallery[galleryType].clearSearch.type});

  return {
    searchPreview,
    search,
    seedSearch,
    clearSearchResults,
  };
};

export default galleryActions;

interface SearchParams {
  page: number;
  [key: string]: any;
}

type Action = (...args: any[]) => void;

type CallbackType = Action[] | [];

interface PlpGalleryActionProps {
  galleryType: 'plp';
}

interface CollectionGalleryActionProps {
  galleryType: 'collection' | 'artist';
  gallerySlug: string;
}

type UnionKeys<T> = T extends T ? keyof T : never;
type StrictUnionHelper<T, TAll> = T extends any
  ? T & Partial<Record<Exclude<UnionKeys<TAll>, keyof T>, never>>
  : never;
type StrictUnion<T> = StrictUnionHelper<T, T>;

type GalleryActionProps = StrictUnion<PlpGalleryActionProps | CollectionGalleryActionProps>;
