import React from 'react';
import _ from '../../utils/_';
import Arrow from '../icons-af/arrow';

const Pagination = ({pages, active, onClick, href}: propTypes) => {
  const handleClick = (page) => (e) => {
    e.preventDefault();
    onClick(page);
  };

  const itemList = (xs) =>
    xs.map((x) => (
      <li className={'page-item' + (active === x ? ' active' : '')} key={x}>
        <a className="page-link" href={href(x)} onClick={handleClick(x)}>
          {x}
        </a>
      </li>
    ));

  const dots = () => (
    <li className="page-item disabled">
      <a className="page-link">...</a>
    </li>
  );

  return (
    <nav aria-label="Page pagination">
      <div className="container pagination-container">
        <ul className="pagination">
          <li className={'page-item' + (active === 1 ? ' disabled' : '')}>
            <a
              className="page-link"
              href={href(active - 1)}
              aria-label="Previous"
              onClick={handleClick(active - 1)}
            >
              <span aria-hidden="true">
                <Arrow width="18px" height="100%" rotate={180} />
              </span>
              <span className="sr-only">Previous</span>
            </a>
          </li>
          {pages <= 8 && itemList(_.range(1, pages + 1))}
          {pages > 8 && active <= 4 && (
            <>
              {itemList([1, 2, 3, 4, 5, 6])}
              {dots()}
              {itemList([pages])}
            </>
          )}
          {pages > 8 && active >= pages - 3 && (
            <>
              {itemList([1])}
              {dots()}
              {itemList(_.range(pages - 6 + 1, pages + 1))}
            </>
          )}
          {pages > 8 && active > 4 && active < pages - 3 && (
            <>
              {itemList([1])}
              {dots()}
              {itemList(_.range(active - 2, active + 2 + 1))}
              {dots()}
              {itemList([pages])}
            </>
          )}
          <li className={'page-item' + (active === pages ? ' disabled' : '')}>
            <a
              className="page-link"
              href={href(active + 1)}
              aria-label="Next"
              onClick={handleClick(active + 1)}
            >
              <span aria-hidden="true">
                <Arrow width="18px" height="100%" rotate={0} />
              </span>
              <span className="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

interface propTypes {
  pages: number;
  active: number;
  onClick: (page) => any;
  href: (page) => string;
}

export default Pagination;
