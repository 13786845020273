import React from 'react';

const SVG = ({width = '22px', height = '23px'}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    className="bi bi-bell"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Bell</title>
    <g>
      <path
        fillRule="evenodd"
        d="M14.62,10,12.51,7.92A15.88,15.88,0,0,0,6.19,19.67h3A12.45,12.45,0,0,1,14.62,10ZM34.8,19.67h3A15.73,15.73,0,0,0,31.64,7.92L29.53,10A12.74,12.74,0,0,1,34.8,19.67Zm-3,.75a9.69,9.69,0,0,0-7.53-9.49V9.88a2.26,2.26,0,0,0-4.52,0v1.05a9.69,9.69,0,0,0-7.53,9.49V28.7l-3,3v1.51H34.8V31.72l-3-3ZM22,37.74h.6a2.62,2.62,0,0,0,2.11-1.81,2.84,2.84,0,0,0,.3-1.2H19A3,3,0,0,0,22,37.74Z"
      />
    </g>
  </svg>
);

export default SVG;
