import React, {useEffect, useState} from 'react';
import {emitFactory, emit} from '../../utils/events';

import Button from '../button/button';
import Location from '../icons-af/location';

import Rating from '../rating/rating';
import {get, urlencoded} from '../../services/artfinder-service';
import ArtistAvatar from '../artist-avatar/artist-avatar';
import _ from '../../utils/_';
import {H2, P} from '../typography/typography';
import {followArtistEvent} from '../../utils/gtm';
import {useSelector} from 'react-redux';
import {selectUserSettingsData} from '../../../redux/reducers/userSettingsReducer';
import CtaButton from '@/atoms/Buttons/CTA/CtaButton';
import './artist.scss';

const Artist = ({auth, artist}) => {
  const [following, setFollowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(artist.favourite_count);
  const setting = useSelector(selectUserSettingsData);
  const artistGalleryInfo = {
    represented_by_gallery: artist.is_represented_by_gallery,
    gallery_name: artist.provider_display_name,
    gallery_id: artist.provider_id,
    gallery_url: `${process.env.HOST}/${artist.provider_slug}`,
  };

  const getAndSetFollowing = () => {
    get('/interest/follows.js')
      .then(_.pipe(_.includes(artist.slug), setFollowing))
      .catch((err) => {
        console.log('interest follow api error', err);
      });
  };

  useEffect(getAndSetFollowing, []);

  const handleFollowClick = () => {
    if (!auth.isAuthenticated()) {
      emit('CLICK_ON_JOIN');
      return;
    }
    setLoading(true);
    urlencoded('/interest/', {
      object_type: 'artists.Artist',
      object_id: artist.id,
      interested: !following,
    })
      .then(() => {
        setCount(following ? count - 1 : count + 1);
        getAndSetFollowing();
        followArtistEvent(artist);
      })
      .catch((err) => {
        console.log('artist follow error', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const countFilter = () => {
    if (count < 1000) {
      return count;
    } else if (count % 1000 === 0) {
      return `${count / 1000}K`;
    } else {
      return `${Math.floor(count / 100) / 10}K+`;
    }
  };

  const formatDate = (date) => {
    const newDate = new Date(date.replace(/-/g, '/').split(' ')[0]);
    return newDate.toLocaleDateString(setting.language, {
      month: 'long',
      year: 'numeric',
    });
  };

  return (
    <div className={'artist' + (following ? ' artist-following' : '')}>
      <div
        className="artist-cover"
        style={{
          backgroundImage: `url(${artist.cover_url})`,
        }}
      ></div>
      <div className="container">
        <div className="artist-left">
          <ArtistAvatar src={artist.avatar_url} alt={artist.name} galleryInfo={artistGalleryInfo} />

          <H2 style="title">{artist.name}</H2>

          {artist.accepts_commissions && (
            <CtaButton
              style="tertiary"
              onClick={emitFactory('CLICK_COMMISSION_ARTIST')}
              children={`${!auth.isAuthenticated() ? 'New! ' : ''}Commission artist`}
            />
          )}
          {artist.joined_at && (
            <P style="p1">
              Joined Artfinder: {formatDate(artist.joined_at)}
              <br />
            </P>
          )}
          <P style="p1">
            Artworks for sale:{' '}
            <a href={`/artist/${artist.slug}/`} title="View all artworks by ">
              {artist.artworks_for_sale}
            </a>
          </P>

          {artist.review_data.review_count && (
            <div className="artist-rating">
              <Rating value={artist.review_data.seller_rating} size="15px" readOnly /> (
              {artist.review_data.review_count})
            </div>
          )}

          <P style="p1">
            <Location width="15px" height="15px" /> {artist.country}
          </P>

          <div className="artist-buttons">
            <Button
              active={following}
              disabled={loading}
              onClick={handleFollowClick}
              icon="heart"
              title={`${following ? 'Unfollow' : 'Follow'} ${artist.name}`}
            >
              {following ? 'Following' : 'Follow'}
              <span className="counter"> ({countFilter()})</span>
            </Button>

            <CtaButton onClick={emitFactory('CLICK_CONTACT_ARTIST')}>Contact me</CtaButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Artist;
