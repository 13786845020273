import {post, multipart} from '../services/artfinder-service';
import constantsFactory from '../utils/constants';
const {TRACKING} = constantsFactory();

export const trackPage = (canonicalUrl, products = [], id) => {
  const type = products.length > 0 ? 'artists.Artist' : 'products.Product';
  const obj = {
    object_type: type,
    object_id: id,
  };

  const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object') {
      Object.keys(data).forEach((key) => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
  };

  const jsonToFormData = (data) => {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
  };

  post(TRACKING.IMPRESSIONS, {
    url: canonicalUrl,
    products: products,
  })
    .then(
      multipart(TRACKING.VISITS, jsonToFormData(obj)).catch((err) => {
        console.log('tracking [visits] error', err);
      }),
    )
    .catch((err) => {
      console.log('tracking [visits] error', err);
    });
};
