/* eslint-disable camelcase */
import React, {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {symbolFromCurrency} from '../../../services/currencies-service';
import {emit} from '../../../utils/events';
import useBasket from '../../../hooks/use-basket/use-basket';

import StarFull from '../../icons-af/star-full';
import StarHollow from '../../icons-af/star-hollow';
import constantsFactory from '../../../utils/constants';
import {H5, H6, P} from '../../typography/typography';
import LazyImage from '../../lazy-image/lazy-image';
import Button from '../../button/button';
import useAuth from '../../../hooks/use-auth/use-auth';
import {post} from '../../../services/artfinder-service';
import {getCustomerFavourites} from '../../../../redux/actions/productActions';
import CardRibbon from '../../card-ribbon/card-ribbon';
import {selectUserSettingsData} from '../../../../redux/reducers/userSettingsReducer';
import {pushGAAddToWishlistEvent, pushGASelectItemEvent} from '../../../utils/gtm';
import {increaseBasketCount} from '../../../../redux/actions/settingsActions';
import {selectProductData} from '../../../../redux/reducers/productReducer';
import {productClick} from '../../../../redux/actions/trackingActions';
import './product-card.scss';

const {API} = constantsFactory();
const allImageTypes = {
  artwork: 'full_frontal_image',
  'in room': 'incontext_image',
  main: 'main_image',
};

const ProductCard = ({
  product,
  imageType = 'main',
  small = false,
  trackingFunc,
  isAttraqt,
  onClick = null,
  onAddToBasket = null,
}: propTypes) => {
  const {t, ready} = useTranslation();
  const card: any = useRef();
  const [favourited, setFavourited] = useState(false);
  const [loading, setLoading] = useState(false);
  const {customerFavourites, artistId} = useSelector(selectProductData);
  const auth = useAuth();
  const basket: any = useBasket();
  const dispatch = useDispatch();
  const settings = useSelector(selectUserSettingsData);
  const image = product[allImageTypes[imageType]];
  const [title, setTitle] = useState('Add to Basket');
  const [addedToBasket, setAddedToBasket] = useState(false);

  useEffect(() => {
    if (ready) {
      setTitle('button.add_to_basket');
    }
  }, [ready]);

  const boostedIcon = () =>
    product.is_boosted ? <StarFull /> : product.was_boosted ? <StarHollow /> : '';

  const handleProductClick = (e, product) => {
    // call any onclick callback passes
    if (onClick) {
      onClick(e, product);
    }

    // always track card click
    pushGASelectItemEvent(product, settings.currency);
    trackProductClick();
  };

  const price = () => Math.round(product.prices[settings.currency]);

  const originalPrice = () => Math.round(product.original_prices[settings.currency]);

  const productPrice = () => {
    return (
      <P style="p1">
        {product.has_original_price && product.is_in_stock && (
          <>
            <del>
              {symbolFromCurrency(settings.currency)}
              {originalPrice()}
            </del>

            <br />

            <strong className="text-primary">
              {'Now '}
              {symbolFromCurrency(settings.currency)}
              {price()}
            </strong>
          </>
        )}

        {!product.has_original_price && product.is_in_stock && (
          <strong>
            {symbolFromCurrency(settings.currency)}
            {price()}
          </strong>
        )}

        {!product.is_in_stock && (
          <del>
            <strong>
              {symbolFromCurrency(settings.currency)}
              {price()}
            </strong>
          </del>
        )}

        {!product.is_in_stock && !product.lowest_print_prices && (
          <>
            &nbsp;
            <strong className="product-tag-sold">Sold</strong>
          </>
        )}

        {(product.is_in_stock || product.lowest_print_prices) && product.is_new && (
          <>
            &nbsp;
            <strong className="text-success">New</strong>
          </>
        )}
      </P>
    );
  };

  const trackProductClick = () => {
    const url = `/product/${product.slug}/`;
    dispatch(productClick(url, product.slug));
  };

  useEffect(() => {
    if (trackingFunc) {
      const yPos = card.current && Math.round(card.current.getBoundingClientRect().top);
      trackingFunc({
        product_slug: product.slug,
        y_pos: yPos,
      });
    }
  }, []);

  useEffect(() => {
    setFavourited(checkForFavourite());
  }, [customerFavourites]);

  const checkForFavourite = () => customerFavourites.includes(product.id);

  const clickFavourite = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!auth.isAuthenticated()) {
      emit('CLICK_ON_JOIN');
      return;
    }
    setLoading(true);
    post(API.COLLECTIONS_API, {
      action: 'favourite',
      product_id: product.id,
    })
      .then(() => {
        pushGAAddToWishlistEvent(product, settings.currency);
        dispatch(getCustomerFavourites(artistId, auth));
      })
      .catch((err) => {
        console.log('click favourite error: ', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className={`product-card${small ? ' product-card--small' : ''}${
        isAttraqt ? ' product-card--attraqt' : ''
      }`}
      ref={card}
    >
      <div className="product-card--body">
        <LazyImage
          imageSrc={image.retina_url}
          alt={product.name}
          width={image.width}
          height={isAttraqt ? 250 : image.height}
          mainColor={product.average_colour}
        />

        <section>
          <H5 style="p1" truncate>
            {product.name} by {product.artist_name}
          </H5>

          {!small && (
            <>
              <H6 style="p1">
                {product.category_full_name}
                {boostedIcon()}
              </H6>

              {productPrice()}
            </>
          )}
        </section>

        {(small || isAttraqt) &&
          (product.is_in_stock || product.lowest_print_prices) &&
          product.is_new && <CardRibbon label="New" />}

        {(small || isAttraqt) && !product.is_in_stock && !product.lowest_print_prices && (
          <CardRibbon label="Sold" theme="red" />
        )}

        <a href={product.url} onClick={(e) => handleProductClick(e, product)} />

        {!small && (
          <div className="product-card__buttons">
            {!isAttraqt && (
              <Button
                active={favourited}
                disabled={loading}
                title="Add to favourites"
                onClick={clickFavourite}
                icon="heart"
              />
            )}

            {product.is_in_stock && !basket.contains(product) && (
              <Button
                title={t(title)}
                onClick={(e) => {
                  e.preventDefault();
                  basket.add(product, settings.currency);
                  if (!addedToBasket) dispatch(increaseBasketCount(1));
                  setAddedToBasket(true);
                  // execute callback if provided
                  if (onAddToBasket) {
                    onAddToBasket(product);
                  }
                }}
                icon="basket"
              />
            )}
          </div>
        )}
      </div>

      {!small && product.lowest_print_prices && (
        <div className="prints-promo">
          <P style="p1-b" color="white">
            Prints available from {symbolFromCurrency(settings.currency)}
            {Math.round(product.lowest_print_prices[settings.currency])}
          </P>

          <a href={product.url} onClick={(e) => handleProductClick(e, product)} />
        </div>
      )}
    </div>
  );
};

interface propTypes {
  product: any;
  imageType?: 'artwork' | 'in room' | 'main';
  trackingFunc?: (product: any) => any;
  small?: boolean;
  isAttraqt?: boolean;
  onClick?: any;
  onAddToBasket?: any;
}

export default ProductCard;
