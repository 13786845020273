import React from 'react';
import './card-ribbon.scss';

const CardRibbon = ({label, theme = 'green'}: ribbonProps) => {
  return <span className={`card-ribbon ${theme}`}>{label}</span>;
};

export default CardRibbon;

export interface ribbonProps {
  label: string;
  theme?: 'green' | 'red';
}
