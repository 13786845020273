import {useState} from 'react';
import _ from '../../utils/_';

const useCookie = (key, defaultValue) => {
  const getCookie = () => (typeof document !== 'undefined') ? _.getCookie(key, document) : defaultValue;
  const [cookie] = useState(getCookie());

  return [cookie];
};

export default useCookie;
